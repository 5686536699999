/* Drawer.css */

.drawer {
    position: fixed;
    top: 0;
    left: -300px; /* Drawer is initially off-screen */
    width: 300px;
    height: 100%;
    background-color: #fff;
    transition: left 0.3s ease-in-out;
  }
  
  .drawer.open {
    left: 0; /* Slide drawer into view */
  }
  
  .drawer-content {
    padding: 20px;
  }
  
  .close-button {
    margin-bottom: 10px;
  }