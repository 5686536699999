.user-stats-card {
    width: 186px;
    height: 126px;
    border: 1px solid #e1e8ed;
    border-radius: 8px;
    padding: 10px;
    background-color: #f7f9fc;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .icon {
    font-size: 24px;
  }
  
  .title {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  
  .view-button {
    background-color: #f7b500;
    border: none;
    color: white;
    padding: 4px 10px;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .stats {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    margin-left: 16px;
    margin-right: 16px;
    gap: 20px;

  }
  
  .active-users,
  .inactive-users {
    width: 186px;
    height: 126px;
    background-color: #F5F9FF;
    
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .active-circle {
    width: 9px;
    height: 9px;
    background-color: #28a745;
    border-radius: 50%;
    position: absolute;
    left: 55px;
    top: 52px;
  }
  
  .inactive-circle {
    width: 9px;
    height: 9px;
    background-color: #dc3545;
    border-radius: 50%;
    position: absolute;
    left: 55px;
    top: 52px;
  }
  
  .count {
    font-size: 25px;
    font-weight: bold;
    color: #333;
    width: 39px;
    height: 34px;
    margin-left:16px
  }


  .label {
    color: #555;
    width: 145px;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.1px;
    text-align: left;
    height: 19px;
    text-align:center;
    opacity: 0.7;
  }
  
  .expand-icon {
    position: absolute;
    right: 5px;
    bottom: 5px;
    font-size: 10px;
    color: #333;
    top: 0px;
  }
  


  