.toggle-switch {
    width: 50px;
    height: 25px;
    background-color: #ddd;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .slider {
    width: 25px;
    height: 25px;
    background-color: rgb(31, 29, 28);
    border-radius: 50%;
    position: absolute;
    top: 0;
    transition: transform 0.3s ease;
  }
  
  .toggle-switch.on .slider {
    transform: translateX(25px);
  }
  